import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ConnectWalletButton from 'components/authForms/WalletConnect/ConnectWalletButton';
import { t } from 'i18n/index';


const OnChainModal = ({ open, onSubmit, onSkip, onClose }) => {

  return (
    <Dialog open={open} onClose={ onClose }>
      <DialogTitle>{t('connectLaterModal:addWalletModal_TitleLater')}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
        <p>{t('connectLaterModal:connecLaterFirstContentLabelExplication')}</p>
        <p>{t('connectLaterModal:connecLaterSecondContentLabelExplication')}</p>
        <p>{t('connectLaterModal:connecLaterThirdContentLabelExplication')}</p>
      </DialogContentText>
      </DialogContent>
        <DialogActions classes='MuiDialogActions-root' >
            <Button variant="outlined"
                    color="warning"
                    onClick={ onSkip } // skip
                    classes='MuiButton-outlinedWarning'>
              {t('connectLaterModal:addWeb3Modal_Later')}
            </Button>
            <div style={{ marginLeft: '15px' }}>
              <ConnectWalletButton challengeType='connect' done={onSubmit} />
            </div>
      </DialogActions>
    </Dialog>
  );
}

OnChainModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onSkip: PropTypes.func,
  onClose: PropTypes.func,
};

export default OnChainModal;
